<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "TotalBalance",
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState('accounts', [
      'totals',
    ]),
  },
  mounted() {
    if (!this.totals.total_balance) {
      this.loadTotals();
    }
  },
  methods: {
    ...mapActions('accounts', ['getTotals']),
    async loadTotals() {
      try {
        this.loading = true;
        await this.getTotals();
        this.loading = false;

      } catch (err) {
        console.error(err);
      }
    },
    updateTotals() {
      this.loadTotals();
    },
  }
}
</script>

<template>
  <div class="d-flex align-center">
    <v-table
        density="compact"
        style="max-width: 500px;"
    >
      <tbody>
      <tr>
        <td>всего на счетах</td>
        <td>{{ totals.total_balance }}</td>
      </tr>
      <tr>
        <td>топ 5 кошельков</td>
        <td>{{ totals.top_5_users_balance }}</td>
      </tr>
      <tr>
        <td>на адресе вывода</td>
        <td>{{ totals.withdrawal_wallet_balance }}</td>
      </tr>
      </tbody>
    </v-table>
    <v-btn
        prepend-icon="mdi-reload"
        density="comfortable"
        variant="text"
        size="small"
        :loading="loading"
        @click="updateTotals"
    >
      Обновить
    </v-btn>
  </div>
</template>
