import { getTotals } from "../../plugins/api-service";

const state = {
    totals: {
        total_balance: null,
        top_5_users_balance: null,
        withdrawal_wallet_balance: null,
    },
};

const getters = {
};

const mutations = {
    setTotals: (state, totalsResult) => {
        state.totals.total_balance = totalsResult.total_balance;
        state.totals.top_5_users_balance = totalsResult.top_5_users_balance;
        state.totals.withdrawal_wallet_balance = totalsResult.withdrawal_wallet_balance;
    },
};

const actions = {
    getTotals: async ({ commit }) => {
        try {
            const totalsResult = await getTotals();
            if (totalsResult?.success && totalsResult.result) {
                commit('setTotals', totalsResult.result);
                return totalsResult;
            }
        } catch (error) {
            let message = error;

            if (error.response && error.response.data) {
                message = error.response.data;
            }
            return message;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
