<template>
    <v-container fluid class="ma-4">
      <TotalBalance class="mb-3" />
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="search"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4"
                item-value="name"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                                v-model="search.id"
                                variant="outlined"
                                hide-details
                                type="number"
                                placeholder="Search id..."
                                class="ma-1"
                                @input="loadItems"
                                density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.first_name"
                                hide-details
                                variant="outlined"
                                placeholder="Search name..."
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                </v-row>
            </template>
            <template v-slot:[`item.user.id`]="{ item }">
                <router-link :to="`/users?id=${item.selectable.user.id}`">{{ item.selectable.user.id }}</router-link>
            </template>
            <template v-slot:[`item.user.first_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.first_name}`">{{ item.selectable.user.first_name }}</router-link>
            </template>
            <template v-slot:[`item.user.last_name`]="{ item }">
                <router-link :to="`/users?name=${item.selectable.user.last_name}`">{{ item.selectable.user.last_name }}</router-link>
            </template>
            <template v-slot:[`item.total_balance`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.total_balance, 4) }}
            </template>
            <template v-slot:[`item.prepaid_balance`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.prepaid_balance, 4) }}
            </template>
            <template v-slot:[`item.bonus_balance`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.bonus_balance, 4) }}
            </template>
            <template v-slot:[`item.latest_deposit_withdrawal`]="{ item }">
                <template v-if="item.selectable.latest_deposit_withdrawal">
                    <router-link :to="`/transactions?id=${item.selectable.user.id}`">{{ moment(item.selectable.latest_deposit_withdrawal?.created_at * 1000).format('DD.MM.YYYY HH:mm') }}</router-link>
                </template>
                <template v-else>-</template>
            </template>
            <template v-slot:[`item.current_week_profit`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.current_week_profit, 4) }}
            </template>
            <template v-slot:[`item.last_week_profit`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.last_week_profit, 4) }}
            </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
  import { getAccounts } from "../plugins/api-service";
  import moment from 'moment';
  import currencyFormatter from "../mixins/currencyFormatter"
  import TotalBalance from "@/components/TotalBalance.vue";

  export default {
    name: 'UsersAccounts',
    components: { TotalBalance },
    mixins: [currencyFormatter],
    data: () => ({
      itemsPerPage: 30,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'user.id',
        },
        { title: 'Имя', key: 'user.first_name', align: 'start', sortable: false },
        { title: 'Фамилия', key: 'user.last_name', align: 'start', sortable: false },
        { title: 'Всего на счетах', key: 'total_balance', align: 'start'},
        { title: 'Бонусный', key: 'bonus_balance', align: 'start'},
        { title: 'Препэйд', key: 'prepaid_balance', align: 'start'},
        { title: 'Доход текущей недели', key: 'current_week_profit', align: 'start', sortable: false },
        { title: 'Доход прошлой недели', key: 'last_week_profit', align: 'start', sortable: false },
        { title: 'Последняя транзакция ввода/вывода', key: 'latest_deposit_withdrawal', align: 'end', sortable: false },
      ],
      serverItems: [],
      loading: true,
      totalItems: 9999999,
      page: 1,
      search: {
        id: null,
        first_name: '',
        email: ''
      },
      sort: {}
    }),
    mounted() {
      this.loadItems()
    },
    methods: {
      moment,
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        this.loading = true;

        if(this.$route.query.id) {
          this.search.id = this.$route.query.id;
        }

        this.$route.query = {};

        let page = params?.page || 1;
        let itemsPerPage = params?.itemsPerPage || (this.itemsPerPage > 0 ? this.itemsPerPage :  this.totalItems);

        let requestParams = {
          offset: (page - 1) * itemsPerPage,
          limit: page === -1 ? this.totalItems : itemsPerPage,
          user_id: this.search.id || this.$route.params.id,
          order_direction: 'desc'
        };

        if(this.search.first_name) {
          requestParams.name_contains = this.search.first_name;
        }
        if(this.search.email) {
          requestParams.email_contains = this.search.email;
        }

        if(this.sort.order_direction && this.sort.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }

        if(params?.order_direction && params?.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }


        await getAccounts(requestParams).then(response=>{
          if(response.success) {
            this.serverItems = response.result.items;
            this.totalItems = response.result.total;
            this.loading = false;
          }
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
    .adm-container {
        min-height: calc(100vh - 64px);
    }
</style>