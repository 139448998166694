import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import user from '@/store/modules/user';
import accounts from '@/store/modules/accounts';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    accounts,
  },
  plugins: [createPersistedState({
    paths: ['user.sessId']
  })],
})
